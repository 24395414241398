import React from "react";
import Nav_Links from "./NavLinks";
import { Link } from "react-router-dom";

export const MobileNav = ({ menuToogle, setMenuToogle }) => {
  const navigation = Nav_Links();

  return (
    <div
      className="mobile-nav"
      style={{
        top: menuToogle ? "0" : "-200%",
        transition: "all 0.5s",
      }}
    >
      <p
        className="text-end text-[2.8rem] text-white font-medium"
        onClick={() => {
          setMenuToogle(!menuToogle);
          window.document.body.classList.remove("bg-freeze");
        }}
      >
        X
      </p>
      <ul className="text-[1.5rem] font-medium text-white">
        {navigation.map((links, id) => (
          <li key={id}>
            <Link to={links.src}>{links.linkName}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
