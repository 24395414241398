import React from "react";

import one from "../../assets/svg/one.svg";
import two from "../../assets/svg/two.svg";
import three from "../../assets/svg/three.svg";

const svg = [one, two, three];

export const CommonSection = ({ details }) => {
  return (
    <div className="common-section-container">
      <div className={`right lg:w-[50%] w-full h-full bg-no-repeat`}>
        <div className="md:grid grid-cols-3 gap-3">
          <div className="col-span-2 lg:max-w-[400px] w-full aspect-[4/4]">
            <img
              src={details.Image[0]}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className="col-span-1 md:flex flex-col gap-2 hidden">
            <img
              src={details.Image[1]}
              alt=""
              className="w-full h-full object-cover"
            />
            <img
              src={details.Image[2]}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
      <div className="left lg:w-[50%] w-[100%]">
        <h2 className="section-heading-h2">{details.Title}</h2>
        <h3 className="section-heading-h3 !mt-2">{details.Heading}</h3>
        <p className="para sm:mt-5 mt-2">{details.Description}</p>

        {details.chefDetails && (
          <div className="flex md:justify-start justify-center gap-16 mt-8">
            {details.chefDetails.map((d, index) => {
              return (
                <div>
                  <img src={svg[index]} alt="" className="w-full h-full" />
                </div>
                // <div className="md:w-[150px] w-[85px] min-h-[126px] border border-[#d86b1f] flex  flex-col gap-4 items-center justify-end pb-4">

                //   <div>
                //     <LuChefHat className="text-[4rem]" />
                //     <span className="sr-only">chef-cap-icon</span>
                //   </div>
                //   <h3 className="capitalize md:text-[1.4rem] text-[1.2rem] text-center">
                //     {d}
                //   </h3>
                // </div>
              );
            })}
          </div>
        )}

        {details.Button && (
          <div>
            <button className="btn-color btn-active text-white mt-10">
              {details.Button}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
