import React from "react";
import ReactStars from "react-rating-stars-component";

export const TestimonalCard = ({ details }) => {
  return (
    <div className="relative">
      <div className="relative left-[50%] top-16  translate-x-[-50%] max-w-28  aspect-[1/1]">
        <img
          src={`${"https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"}`}
          alt=""
          className="rounded-full object-cover w-full h-full"
        />
      </div>
      <div className="testimonal-card ">
        <div className="mt-10">
          <h2 className="testimonal-h2">
            {/* {details?.author_name}
             */}
            Nicolo Svensky
          </h2>
          <div className="flex justify-center">
            <ReactStars
              count={details?.rating}
              edit={false}
              size={24}
              color={"#CFAE6F"}
              half={true}
            />
          </div>
          <p className="testimonal-para">
            {/* {details?.text}
             */}
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae
            quas deleniti amet molestiae? Incidunt temporibus, velit ipsum culpa
            cumque id dolores quos sapiente corporis, nobis, maxime officiis
            soluta totam facere!
          </p>
        </div>
      </div>
    </div>
  );
};
