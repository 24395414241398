import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import Header from "../components/Header/Navbar";

const Banner = ({ details, isSlide }) => {
  return (
    <div>
      <Swiper
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ el: "#swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
        }}
        modules={[Autoplay, Navigation, Pagination]}
        slidesPerView={1}
        className="relative"
      >
        <div className="absolute w-[100%] z-[100] md:top-14 top-0">
          <Header className=" bg-green-dark m-auto" />
        </div>

        {isSlide ? (
          details?.map((i, index) => (
            <SwiperSlide key={index}>
              <div
                className="banner-image-container"
                style={{
                  backgroundImage: `url(${i.Image})`,
                }}
              >
                <div className="flex justify-center items-center flex-col max-w-[700px] mx-auto text-white px-2 md:mt-0 mt-20">
                  <p
                    style={{ fontFamily: "cursive-font" }}
                    className="banner-para Playfair italic"
                  >
                    {i.Description}
                  </p>
                  <h1 className="banner-heading-h1">{i.Title}</h1>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <div
              className="banner-image-container"
              style={{
                backgroundImage: `url(${details[0]?.Image})`,
              }}
            >
              <div className="flex justify-center items-center flex-col max-w-[700px] mx-auto text-white px-2 md:mt-0 mt-20">
                <p
                  style={{ fontFamily: "cursive-font" }}
                  className="banner-para Playfair italic"
                >
                  {details[0]?.Description}
                </p>
                <h1 className="banner-heading-h1">{details[0]?.Title}</h1>
              </div>
            </div>
          </SwiperSlide>
        )}

        <div
          id="swiper-pagination"
          className="absolute flex justify-center bottom-2 py-8 left-0 w-full z-20"
        ></div>
      </Swiper>
    </div>
  );
};

export default Banner;
