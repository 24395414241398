import React, { useEffect, useState } from "react";
import { RiArrowUpDoubleLine } from "react-icons/ri";

import { Link } from "react-router-dom";
import footerLogo from "../../assets/footerspicelogo.png";

import instagramIcon from "../../assets/svg/instagram.svg";
import facebookIcon from "../../assets/svg/facebook.svg";

import phone from "../../assets/svg/fillphone.svg";
import mail from "../../assets/svg/outlinemail.svg";
import clock from "../../assets/svg/clock.svg";
import location from "../../assets/svg/outlinelocation.svg";

import spicehaven from "../../assets/spicehaven.png";

const Footer = () => {
  const [scrollTop, setScrollTop] = useState(false);
  const checkScroll = () => {
    if (window.scrollY > 300) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);
  return (
    <footer className="bg-green-dark max-w-[1600px] mx-auto">
      <div className="max-width-container mx-auto px-8 ">
        <div className="grid lg:grid-cols-4 lg:gap-16 gap-8">
          {/* Loga and Link container****** */}
          <div className="flex justify-center">
            <div className="flex lg:justify-center">
              <img src={footerLogo} alt="" className="w-64" />
            </div>
          </div>

          {/* Location****** */}
          <div>
            <h2 className="text-[#FFFFFF] md:text-[3.2rem] text-[2rem] font-extrabold font-playfair">
              Location
            </h2>

            <div className="flex gap-4 mt-3 items-start text-[1.4rem] font-normal">
              <img src={location} alt="location-icon" className="w-6 mt-2" />
              <p className="text-[1.5rem] font-normal text-white ">
                Lorem Ipsum is simply dummy text of the printing
              </p>
            </div>
          </div>

          {/* Opening Hours****** */}
          <div>
            <h2 className="text-[#FFFFFF] md:text-[3.2rem] text-[2rem] font-extrabold font-playfair">
              Opening Hours
            </h2>

            <div className="flex flex-col text-[1.4rem] font-normal text-white mt-3">
              <p className="flex items-center gap-4">
                <img src={clock} alt="" className="w-6" /> Mon-Saturday : 11am -
                11pm
              </p>
              <p className="flex items-center gap-4">
                <img src={clock} alt="" className="w-6" /> Sunday : Closed
              </p>
            </div>
          </div>

          {/* Reservation******* */}
          <div>
            <h2 className="text-[#FFFFFF] md:text-[3.2rem] text-[2rem] font-extrabold font-playfair">
              Reservations
            </h2>

            <div className="flex flex-col mt-3 text-[1.4rem] font-normal text-white">
              <Link className="flex items-center gap-4">
                <img src={phone} alt="phone-icon" className="w-6" />
                +971 XX XXXXXXX
              </Link>
              <Link className="flex items-center gap-4">
                <img src={mail} alt="mail-icon" className="w-6" />{" "}
                reservations@loremipsum.com
              </Link>
            </div>
          </div>
        </div>
        <hr className="mt-16" />

        <div className="mt-6 flex md:flex-row flex-col justify-between">
          <div className="flex md:flex-row flex-col gap-5 md:items-center">
            <p className="text-[1.5rem] text-[#6C757D]">
              &copy;Copyright Spice Haven
            </p>
            <h2 className="text-[2rem] ">
              <small className="text-[#6C757D] text-md">
                &copy; Designed & Developed by{" "}
                <a href="https://eazotel.com" target="_blank">
                  eazotel
                </a>{" "}
                - All Right reserved
              </small>
            </h2>
          </div>
          <div className="flex gap-2 lg:justify-center mt-3 text-[1.4rem] font-normal">
            <Link to="/reservation">
              <img src={instagramIcon} alt="instagram-icon" className="w-14" />
            </Link>
            <Link>
              <img src={facebookIcon} alt="facebook-icon" className="w-14" />
            </Link>
          </div>
        </div>
      </div>

      {scrollTop && (
        <div
          className="scroll-to-top"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <RiArrowUpDoubleLine className=" up-icon" text />
        </div>
      )}
    </footer>
  );
};

export default Footer;
