import React from "react";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";
import bannerImg from "../../assets/contactbanner.png";
// import HeroBg1 from "../../assets/Main1.png";

import userIcon from "../../assets/svg/filluser.svg";
import phoneIcon from "../../assets/svg/fillphone.svg";
import mailIcon from "../../assets/svg/fillmail.svg";
import messageIcon from "../../assets/svg/fillmessage.svg";

import phone from "../../assets/svg/outlinephone.svg";
import outlinemail from "../../assets/svg/outlinemail.svg";
import outlinelocation from "../../assets/svg/outlinelocation.svg";

import { Link } from "react-router-dom";

const aboutBannerDetails = [
  {
    Title: "Contact us",
    // Description: "Top Services and Premium Cuisine",
    Image: bannerImg,
  },
];

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <section className="home-banner-section">
        <Banner details={aboutBannerDetails} />
      </section>

      <section className="common-section">
        <h2 className="text-center text-[3.2rem] text-[#363636] font-playfair font-extrabold">
          Contact Us
        </h2>

        <div className="flex lg:flex-row flex-col gap-10 max-width mt-10">
          <div className="lg:w-[65%]">
            <h2 className="text-[1.8rem] text-[#000000] font-medium font-playfair">
              Contact Details
            </h2>

            <div className="flex flex-col gap-4 mt-8 text-[1.4rem] font-normal text-[#5B5B5B]">
              <Link className="flex items-center gap-2">
                <img src={phone} alt="phone-icon" className="w-6" /> +971 XX
                XXXXXXX
              </Link>
              <Link className="flex items-center gap-2">
                <img src={outlinemail} alt="mail-icon" className="w-6" />
                reservations@loremipsum.com
              </Link>
              <p className="flex items-center gap-2">
                <img
                  src={outlinelocation}
                  alt="location-icon"
                  className="w-6"
                />{" "}
                Lorem Ipsum is simply dummy text of the printing
              </p>
            </div>

            <div className="mt-10 h-[325px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.9581118058!2d76.76289349687478!3d28.644285696143115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1713244172533!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div
            className="lg:w-[35%] shadow-xl bg-green-dark py-8"
            style={{
              boxShadow: "6px 6px 0px #CFAE6F",
            }}
          >
            <form onSubmit={handleSubmit}>
              {/* Input and text field****** */}
              <div className="px-10">
                <h2 className="text-[2.4rem] text-cream-primary font-playfair">
                  Get in Touch!
                </h2>

                <div className="flex flex-col gap-14 mt-5">
                  <div className="flex gap-3 items-center border-b border-b-cream-primary py-5">
                    <img
                      src={userIcon}
                      alt="user-icon"
                      width={13}
                      className="w-10 "
                    />
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="w-full outline-none bg-transparent text-[1.4rem] text-cream-primary"
                      name="username"
                      // value={formData.username}
                      // onChange={handleChnage}
                    />
                  </div>
                  <div className="flex gap-3 items-center border-b border-b-cream-primary py-5">
                    <img
                      src={phoneIcon}
                      alt="user-icon"
                      width={13}
                      className="w-10 "
                    />
                    <input
                      type="text"
                      placeholder="Phone"
                      className="w-full outline-none bg-transparent text-[1.4rem] text-cream-primary"
                      name="phone"
                      // value={formData.phone}
                      // onChange={handleChnage}
                    />
                  </div>
                  <div className="flex gap-3 items-center border-b border-b-cream-primary py-5">
                    <img
                      src={mailIcon}
                      alt="user-icon"
                      width={13}
                      className="w-10 "
                    />
                    <input
                      type="text"
                      placeholder="Email"
                      className="w-full outline-none bg-transparent text-[1.4rem] text-cream-primary"
                      name="email"
                      // value={formData.email}
                      // onChange={handleChnage}
                    />
                  </div>
                  <div className="flex gap-3 items-center border-b border-b-cream-primary py-5">
                    <img
                      src={messageIcon}
                      alt="user-icon"
                      width={13}
                      className="w-10 "
                    />
                    <input
                      type="text"
                      placeholder="Message"
                      className="w-full outline-none bg-transparent text-[1.4rem] text-cream-primary"
                      name="message"
                      // value={formData.message}
                      // onChange={handleChnage}
                    />
                  </div>
                </div>
              </div>

              {/* Send Messaage Button****** */}
              <div className="mt-16 flex px-10">
                <button type="submit" className="btn-color btn-active">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
