import React from "react";
import CallSlider from "../Sliders/CallSlider";
import phone from "../../assets/svg/fillphone.svg";
import { Link } from "react-router-dom";
import urdu from "../../assets/urdu.png";

const dots = new Array(5).fill("");

export const CallSection = () => {
  return (
    <div className="call-for-resturant">
      {/* left area*** */}

      <div className="left w-[50%]">
        <div>
          {/* call us restaurant*** */}

          <div className="flex justify-center">
            <img src={urdu} alt="logo" className="w-64" />
          </div>

          {/* openint hours**** */}
          <h3 className=" text-white text-center text-[2.5rem] mt-3 font-bold">
            Opening Hours
          </h3>

          {/* dots**** */}
          <div className="flex justify-center gap-5 mt-6">
            {dots.map((d, index) => (
              <div
                className="w-[5px] h-[5px] bg-cream-primary rounded-full"
                key={index}
              ></div>
            ))}
          </div>

          {/* time and date**** */}
          <div className="flex justify-around mt-12">
            <div>
              <h3 className="text-cream-primary text-[1.3rem]">
                Saturday to Sunday
              </h3>
              <h3 className="time">09:00</h3>
              <h3 className="time">09:00</h3>
            </div>
            <div>
              <h3 className="text-cream-primary text-[1.3rem]">
                Saturday to Sunday
              </h3>
              <h3 className="time">09:00</h3>
              <h3 className="time">09:00</h3>
            </div>
          </div>

          {/* line and dot**** */}
          <div className="flex justify-evenly mt-12">
            <div className="w-[120px] h-[1px] bg-[#C19D60]"></div>
            <div className="w-[5px] h-[5px] bg-[#FFFFFF] rounded-full"></div>
            <div className="w-[120px] h-[1px] bg-[#C19D60]"></div>
          </div>

          {/* count***** */}
          <div className="flex justify-center mt-20">
            <div className="w-[260px]">
              <Link
                className="text-[1.6rem] py-6 rounded-md text-white border border-cream-primary text-center flex items-center justify-center
              gap-2"
              >
                <img src={phone} alt="" /> +971 XX XXXXXXX
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* right area**** */}
      <div className="right w-[50%]">
        <CallSlider />
      </div>
    </div>
  );
};
