import React from "react";
import HeroBg1 from "../../assets/opening.avif";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";

const arr = [HeroBg1];

const CallSlider = () => {
  return (
    <div>
      <Swiper
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
        }}
        modules={[Autoplay, Navigation, Pagination]}
        slidesPerView={1}
        className=""
      >
        <div className="swiper-pagination sm:py-12"></div>

        {arr?.map((i, index) => (
          <SwiperSlide key={index}>
            <div
              key={index}
              className="call-image-container px-8"
              style={{
                backgroundImage: `url(${HeroBg1})`,
              }}
            >
              <div className="flex justify-center items-center flex-col sm:gap-10 gap-3 w-[676px] mx-auto text-white">
                {/* <h3 className="section-heading-h3 !text-white">Lorem Ipsum</h3>

                <p className="call-para">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                  accusantium."
                </p>
                <button className="btn-color text-[1.4rem] px-5 py-4 font-medium rounded-sm">
                  Lorem ipsum.
                </button> */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CallSlider;
