import React from "react";

export const ChefCard = ({ details }) => {
  return (
    <>
      <div className="chef-card">
        <div className="chef-image-container">
          <img src={details.Image} alt="" className="w-full" />
          <div className="chef-details">
            <h2 className="font-playfair">{details.Name}</h2>
          </div>
        </div>
      </div>
    </>
  );
};
