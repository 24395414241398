import React, { useEffect, useState } from "react";
// import axios from "axios";
import { TestimonalCard } from "../Cards/TestimonalCard";
// import { useWebContext } from "../../Context/ContextProvider";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";

import { MdArrowRight, MdArrowLeft } from "react-icons/md";

const reviewsArr = [1, 2, 3, 4, 5];

export const TestimonalSLider = () => {
  // const { reviewsArr } = useWebContext();

  return (
    <div className="testimonal-swiper mx-auto px-8">
      <div>
        <Swiper
          loop={true}
          slidesPerView={1}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            el: "#swiper-pagination-testimonal",
            clickable: true,
          }}
          navigation={{
            nextEl: ".next-review",
            prevEl: ".prev-review",
          }}
          modules={[Autoplay, Navigation, Pagination]}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {reviewsArr?.map((i, index) => (
            <SwiperSlide key={index}>
              <TestimonalCard details={i} />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="flex justify-center items-center gap-6 mt-16">
          <div className="prev-review cursor-pointer w-[40px] h-[40px] sm:flex hidden items-center justify-center bg-cream-primary rounded-full  btn-active">
            <MdArrowLeft
              size={18}
              style={{
                color: "white",
              }}
            />
          </div>
          <div id="swiper-pagination-testimonal"></div>
          <div className=" next-review cursor-pointer w-[40px] h-[40px] sm:flex hidden items-center justify-center bg-cream-primary rounded-full btn-active">
            <MdArrowRight
              size={18}
              style={{
                color: "white",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
