import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
// import { MdArrowRight, MdArrowLeft } from "react-icons/md";
import { ChefCard } from "../Cards/ChefCards";

import chef1 from "../../assets/chef1.png";
import chef2 from "../../assets/chef2.png";
import chef3 from "../../assets/chef3.png";

// import axios from "axios";
// import { useWebContext } from "../../Context/ContextProvider";

const chefDetails = [
  {
    Name: "Lorem Ipsum",
    Image: chef1,
  },
  {
    Name: "Lorem Ipsum",
    Image: chef2,
  },
  {
    Name: "Lorem Ipsum",
    Image: chef3,
  },
];

export const ChefSlider = () => {
  // const { reviewsArr } = useWebContext();

  return (
    <div className="chef-swiper">
      <div>
        <Swiper
          loop={true}
          effect="coverflow"
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            el: "#swiper-pagination-chef",
            clickable: true,
          }}
          navigation={{
            nextEl: ".next-review",
            prevEl: ".prev-review",
          }}
          modules={[Autoplay, Navigation, Pagination]}
          breakpoints={{
            600: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {chefDetails?.map((i, index) => (
            <SwiperSlide key={index}>
              <ChefCard details={i} />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* 
        <div
          id="swiper-pagination-chef"
          className="flex justify-center mt-20"
        ></div> */}

        {/* <div>
          <div className="prev-review cursor-pointer w-[40px] h-[40px] sm:flex hidden items-center justify-center bg-[#D86B1F] rounded-full absolute top-[50%] left-0 z-30 btn-active">
            <MdArrowLeft
              size={18}
              style={{
                color: "white",
              }}
            />
          </div>
          <div className=" next-review cursor-pointer w-[40px] h-[40px] sm:flex hidden items-center justify-center bg-[#D86B1F] rounded-full absolute top-[50%] right-0 z-30 btn-active">
            <MdArrowRight
              size={18}
              style={{
                color: "white",
                // background: "#000000",
              }}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};
