// import { useWebContext } from "../../Context/ContextProvider";
const Nav_Links = () => {
  // const { slugs, IsNavBar } = useWebContext();
  // const slug = Object.values(slugs).map((page) => page.Slug);
  // const Links = [
  //   {
  //     isNavBar: IsNavBar["Home"],
  //     linkName: "Home",
  //     src: "/",
  //   },
  //   {
  //     isNavBar: IsNavBar["About"],
  //     linkName: "About Us",
  //     src: `/${slug[0]}`,
  //   },
  //   {
  //     isNavBar: IsNavBar["Service"],

  //     linkName: "Our Service",
  //     src: `/${slug[12]}`,
  //   },
  //   {
  //     isNavBar: IsNavBar["Room"],
  //     linkName: "Rooms",
  //     src: `/${slug[11]}`,
  //   },
  //   {
  //     isNavBar: IsNavBar["Restaurant"],

  //     linkName: "Resturant",
  //     src: `/${slug[10]}`,
  //   },
  //   {
  //     isNavBar: IsNavBar["Contact"],
  //     linkName: "Contact Us",
  //     src: `/${slug[3]}`,
  //   },
  // ];
  const Links = [
    {
      linkName: "Home",
      src: "/",
    },
    {
      linkName: "About Us",
      src: `/About`,
    },
    {
      linkName: "Gallery",
      src: `/gallery`,
    },

    {
      linkName: "Contact Us",
      src: `/contact`,
    },
  ];
  return Links;
};

export default Nav_Links;
