import React from "react";

import urdu from "../../assets/urdu.png";
const dots = new Array(5).fill("");

export const CommonSectionTwo = ({
  children,
  isPicture,
  isDot,
  heading_h2,
  heading_h3,
  button_text,
  button_color,
  pdf,
}) => {
  return (
    <div>
      <div>
        {isPicture && (
          <div className="flex justify-center">
            <img src={urdu} alt="" />
          </div>
        )}

        {heading_h2 && (
          <h2 className="section-heading-h2 text-center">{heading_h2}</h2>
        )}

        {heading_h3 && (
          <h3 className="section-heading-h3 text-center">{heading_h3}</h3>
        )}

        {isDot && (
          <div className="flex justify-center gap-5 mt-3">
            {dots.map((d, index) => (
              <div
                className="w-[5px] h-[5px] bg-black rounded-full"
                key={index}
              ></div>
            ))}
          </div>
        )}

        <div className="common-section-two-children sm:mt-12 mt-5 max-width-container">
          {children}
          <div className="flex items-center justify-between mt-12">
            <div className="sm:flex hidden"></div>
            {button_text && (
              <button
                className={`text-[${
                  button_color ? button_color : "white"
                }] btn-color btn-active !uppercase`}
              >
                {button_text}
              </button>
            )}
            <p className="sm:text-[1.6rem] text-[1.2rem] font-normal border-b border-dotted border-b-[#D86B1F] pb-3">
              {pdf}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
