import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { IoMdArrowDropleft } from "react-icons/io";

export const Menu = () => {
  const [subMenuIndex, setSubMenuIndex] = useState(0);
  const menus = [
    {
      name: "main dishes",
      subMenu: [
        {
          name: "Soft shell crab ",
          desc: `Granny help Granny help you treat yourself with a different meal everydayGranny help you treat yourself with a different meal everydayGranny `,
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Salmon riverland",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
      ],
    },
    {
      name: "starter",
      subMenu: [
        {
          name: "Soft shel",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
      ],
    },
    {
      name: "desert",
      subMenu: [
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
      ],
    },
    {
      name: "sea food",
      subMenu: [
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
      ],
    },
    {
      name: "drinks",
      subMenu: [
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
        {
          name: "Soft shell crab",
          desc: "Granny help you treat yourself with a different meal everyday",
        },
      ],
    },
  ];

  return (
    <div>
      {/* desktop menu********** */}
      <div className="lg:flex gap-5 hidden">
        <div className="left w-[20%]">
          <ul className="flex flex-col gap-6">
            {menus.map((menu, index) => (
              <li
                key={index}
                className="flex justify-between items-center cursor-pointer font-normal"
                onClick={() => setSubMenuIndex(index)}
              >
                <p
                  className="flex items-center gap-5 text-cream-primary text-[1.6rem] capitalize"
                  style={{
                    color: subMenuIndex === index ? "#CFAE6F" : "black",
                  }}
                >
                  <span className="text-[1.2rem]">
                    {index < 9 ? `0${index + 1}` : index + 1}
                  </span>{" "}
                  {menu.name}
                </p>
                {subMenuIndex === index && (
                  <div className="text-[1.5rem]">
                    <IoMdArrowDropleft className="text-cream-primary" />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="rignt w-[80%] border-l border-l-gray-700">
          <ul className="pl-8 flex flex-col gap-6">
            {menus[subMenuIndex].subMenu.map((submenus, index) => (
              <li key={index}>
                <div className="flex gap-4 items-center">
                  <div className="w-[100%]">
                    <div className="flex gap-4 items-center">
                      <div className="w-[70%] flex items-center gap-5">
                        <span className="text-[1.3rem] text-[#D86B1F] font-semibold">
                          {index < 9 ? `0${index + 1}` : index + 1}
                        </span>
                        <h2 className=" text-[1.6rem] capitalize text-black font-medium">
                          {submenus.name}
                        </h2>
                      </div>
                      <div className=" border-b border-b-gray-700 border-dotted h-[5px] w-[20%]"></div>
                      <h3 className="w-[10%] text-[#D86B1F] text-[1.5rem] font-medium text-end">
                        Rs 400
                      </h3>
                    </div>
                    <p className="ml-10 mt-4 text-[1.2rem] text-[#363636] font-light text-justify">
                      {submenus.desc}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* tablet and phone view menu****** */}
      <div className="lg:hidden visible ">
        <ul className="flex flex-col gap-4">
          {menus.map((menu, index) => (
            <MobileViewMenu key={index} details={menu} index={index} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const MobileViewMenu = ({ details, index }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  return (
    <li className="bg-gray-100 px-2 pt-3">
      <div className="flex items-center justify-between">
        <p className="text-[1.2rem]">
          {index < 9 ? `0${index + 1}` : index + 1}
        </p>
        <h2 className="text-[1.5rem] text-[#D86B1F] capitalize font-medium">
          {details.name}
        </h2>
        <div
          onClick={() => setShowSubMenu(!showSubMenu)}
          style={{
            rotate: showSubMenu ? "180deg" : "0deg",
            transition: "all 0.5s",
          }}
        >
          {<FaAngleDown />}
        </div>
      </div>
      <div>
        <ul
          className="flex flex-col gap-8 mt-5"
          style={{
            maxHeight: showSubMenu ? "1200px" : "0px",
            overflow: "hidden",
            transition: "all 0.8s linear",
          }}
        >
          {details.subMenu.map((sub, index) => (
            <li key={index}>
              <div className="">
                <div className="flex justify-between gap-4 items-center">
                  <div>
                    <h2 className="flex gap-5 items-center text-[1.3rem] capitalize font-medium text-black">
                      <span className="text-[0.95rem] text-[#D86B1F]">
                        {index < 9 ? `0${index + 1}` : index + 1}
                      </span>
                      {sub.name}
                    </h2>
                  </div>
                  <h3 className="text-[#D86B1F] text-[1.2rem] font-medium">
                    Rs 400
                  </h3>
                </div>
                <p className="text-[1rem] ml-6 mt-3 font-light">{sub.desc}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};
