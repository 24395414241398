import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/spicehavenlogo.png";
import { MobileNav } from "./MobileNav";
import Nav_Links from "./NavLinks";

import facebookIcon from "../../assets/svg/facebook.svg";
import phoneIcon from "../../assets/svg/fillphone.svg";
import instagramIcon from "../../assets/svg/instagram.svg";

const Navbar = ({ className }) => {
  const [menuToogle, setMenuToogle] = useState(false);

  const navigation = Nav_Links();
  return (
    <nav
      className={`flex items-center text-white max-width justify-between ${className} !py-1`}
    >
      <Link to="/" className="flex items-center">
        <div className="max-w-[207px]  aspect-[4/1.25]">
          <img
            decoding="async"
            loading="lazy"
            src={Logo}
            alt="logo"
            className="w-full h-full"
          />
        </div>
      </Link>

      <div className="flex items-center justify-end gap-12">
        <ul className="flex gap-10 items-center text-[1.5rem] font-medium">
          {navigation.map((links, id) => (
            <li key={id} className="li-links">
              <NavLink to={links.src}>{links.linkName}</NavLink>
            </li>
          ))}
        </ul>
        <div className="flex gap-8 text-[2.4rem]"></div>
        <div className="flex items-center gap-6 text-[1.6rem] font-medium text-white reservation hover:text-cream-primary">
          <Link className="flex items-center gap-2">
            <img src={phoneIcon} alt="phone-icon" className="w-7" /> +971 XX
            XXXXXXX
          </Link>
          <Link to="/reservation">
            <img src={instagramIcon} alt="instagram-icon" className="w-14" />
          </Link>
          <Link>
            <img src={facebookIcon} alt="facebook-icon" className="w-14" />
          </Link>
        </div>
        <div
          className="ham-burger-menu text-[2.8rem]"
          onClick={() => {
            setMenuToogle(!menuToogle);
            window.document.body.classList.add("bg-freeze");
          }}
        >
          <HiMenuAlt3 />
        </div>
      </div>

      <MobileNav menuToogle={menuToogle} setMenuToogle={setMenuToogle} />
    </nav>
  );
};

export default Navbar;
