import React from "react";
import { Helmet } from "react-helmet";
import HeroBg1 from "../../assets/homebanner1.avif";
import bannerImg from "../../assets/event.png";
import ourStortImage from "../../assets/ourstoryimg.png";
import Banner from "../../components/Banner";
import { CallSection } from "../../components/CallSection/CallSection";
import { CommonSection } from "../../components/CommonComponents/CommonSection";
import { CommonSectionTwo } from "../../components/CommonComponents/CommonSectionTwo";
import { Menu } from "../../components/Menu/Menu";
import { TestimonalSLider } from "../../components/Sliders/TestimonalSlider";

import Aboutus1 from "../../assets/aboutus1.avif";
import Aboutus2 from "../../assets/aboutus2.avif";
import Aboutus3 from "../../assets/aboutus3.avif";

const homeBannerDetails = [
  {
    Title: "Welcome to Spice Haven Restaurant",
    Description: "Top Services and Premium Cuisine",
    Image: HeroBg1,
  },
];

const ourStory = {
  Title: "Our Story",
  Heading: "Few words about us",
  Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  Button: `ABOUT US`,
  Image: [Aboutus1, Aboutus2, Aboutus3],
};

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      {/* BANNER SLIDER************** */}
      <section className="home-banner-section">
        <Banner details={homeBannerDetails} isSlide={true} />
      </section>

      {/* OUR STORY******** */}
      <section className="common-section">
        <div className="our-story-section">
          <CommonSection details={ourStory} />
        </div>
      </section>

      {/* CALL FOR RESTAURNAT******** */}
      <section className="common-section">
        <div className="call-section">
          <CallSection />
        </div>
      </section>

      {/* RESTAURNAT MENU************** */}
      <section className="common-section">
        <CommonSectionTwo
          heading_h3={"Restaurants Specialties"}
          button_text={"Reserve a table"}
          isPicture={true}
          pdf={"Dowload PDF"}
        >
          <Menu />
        </CommonSectionTwo>
      </section>

      {/* EVENTES********** */}
      <section className="common-section">
        <div
          className="events-container"
          style={{
            backgroundImage: `url(${bannerImg})`,
          }}
        >
          <div className="content-box">
            <div className="border border-cream-primary rounded-md p-6">
              <h2 className="section-heading-h3 !text-cream-primary text-center">
                Celebrate your special event with us.
              </h2>
              <p className="para !text-white mt-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nullaullamco
              </p>
              <div className="flex justify-center mt-5">
                <button className="btn-color btn-active">Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* TESTIMONAL******** */}
      <section className="common-section">
        <CommonSectionTwo
          isPicture={true}
          isDot={true}
          heading_h3={"Customer Reviews"}
        >
          <TestimonalSLider />
        </CommonSectionTwo>
      </section>
    </>
  );
};

export default Home;
