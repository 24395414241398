import React from "react";
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";
import bannerImg from "../../assets/aboutbanner.png";
import Choose1 from "../../assets/choose1.png";
import Choose2 from "../../assets/choose2.png";

import { CommonSection } from "../../components/CommonComponents/CommonSection";
import Chef from "../../assets/svg/chef.svg";
import Ingredient from "../../assets/svg/ingredient.svg";

import chefImage from "../../assets/chef.png";
import { CommonSectionTwo } from "../../components/CommonComponents/CommonSectionTwo";
import { ChefSlider } from "../../components/Sliders/ChefSlider";
import { TestimonalSLider } from "../../components/Sliders/TestimonalSlider";
import { Link } from "react-router-dom";

const aboutBannerDetails = [
  {
    Title: "About Spice Haven Restaurnat",
    Description: "Enjoy your time in our restaurant with pleasure",
    Image: bannerImg,
  },
];

// const aboutUs = [
//   {
//     Title: "about us",
//     Heading: "Few words about us",
//     Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
//     Image: aboutusimg1,
//   },
//   {
//     Heading: "Few words about us",
//     Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
//     Image: aboutusimg2,
//   },
//   {
//     Title: "why choose us",
//     Heading: "Few words about us",
//     Description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
//     Button: `lorem ipsum`,
//     Image: chefImage,
//     chefDetails: ["experienced chef", "for every taste", "fresh integration"],
//   },
// ];

const About = () => {
  return (
    <div>
      <Helmet>
        <title>About</title>
      </Helmet>
      <section className="home-banner-section">
        <Banner details={aboutBannerDetails} />
      </section>

      {/* About us***** */}
      <section className="common-section">
        <CommonSectionTwo
          heading_h2={"About us"}
          heading_h3={"Lorem ipsum dolor sit amet"}
        >
          <div className="flex flex-col gap-10">
            <p className="text-center text-2xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p className="text-center text-2xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p className="text-center text-2xl">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </CommonSectionTwo>
      </section>

      {/* Choose us**** */}

      <section className="common-section bg-green-dark">
        <div className="max-width-container flex lg:flex-row flex-col items-center gap-6">
          <div className="lg:w-[70%] flex gap-8">
            <div className="w-full aspect-[1/1.1] md:block hidden">
              <img
                src={Choose1}
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
            <div className="w-full aspect-[1/1.1]">
              <img
                src={Choose2}
                alt=""
                className=" h-full w-full object-cover"
              />
            </div>
          </div>
          <div className="lg:w-[30%]">
            <div className="flex flex-col md:gap-8 gap-4">
              <div>
                <h2 className="section-heading-h2 font-playfair !not-italic">
                  Why choose us?
                </h2>
                <h3 className="text-white font-playfair text-[2.8rem]">
                  Lorem ipsum dolor sit amet
                </h3>
              </div>
              <p className="text-white text-[1.5rem]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum.
              </p>
              <div className="flex items-center gap-10">
                <p className="sm:text-[2rem] text-[1.6rem] text-cream-primary flex items-center gap-3">
                  <img src={Chef} alt="" />
                  Experienced Chefs
                </p>
                <p className="sm:text-[2rem] text-[1.6rem] text-cream-primary flex items-center gap-3">
                  <img src={Ingredient} alt="" /> Fresh Ingredients
                </p>
              </div>
              <div className="flex">
                <Link to={"/"} className="btn-color">
                  Reserve a table
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="common-section">
        <CommonSectionTwo
          heading_h2={"Our team"}
          heading_h3={"Met Our Chefs"}
          button_text={"Contact us"}
          button_color={"#FFFFFF"}
        >
          <ChefSlider />
        </CommonSectionTwo>
      </section>

      {/* TESTIMONAL************* */}
      {/* <section className="common-section">
        <CommonSectionTwo
          heading_h2={"What said about us"}
          heading_h3={"Customer Reviews"}
        >
          <TestimonalSLider />
        </CommonSectionTwo>
      </section> */}
    </div>
  );
};

export default About;
