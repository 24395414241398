import React from "react";

import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";
import bannerImg from "../../assets/gallerybanner.png";
import HeroBg1 from "../../assets/Main1.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";

import galleryImg1 from "../../assets/galleryimg1.png";
import galleryImg2 from "../../assets/galleryimg2.png";
import galleryImg3 from "../../assets/galleryimg3.png";
import galleryImg4 from "../../assets/galleryimg4.png";
import galleryImg5 from "../../assets/galleryimg5.png";
import galleryImg6 from "../../assets/galleryimg6.png";

import leftArrow from "../../assets/svg/leftarrow.svg";
import rightArrow from "../../assets/svg/rightarrow.svg";

const aboutBannerDetails = [
  {
    Title: "Gallery Lorem Ipsum Demo Text",
    Description: "Enjoy your time in our restaurant with pleasure",
    Image: bannerImg,
  },
];

const gallery = [
  {
    Title: "Restaurant ",
    Images: [galleryImg1, galleryImg2, galleryImg3, galleryImg2],
  },

  {
    Title: "Food",
    Images: [galleryImg4, galleryImg5, galleryImg6, galleryImg5],
  },
];

const Gallery = () => {
  return (
    <>
      <Helmet>
        <title>Menu</title>
      </Helmet>

      <section className="home-banner-section">
        <Banner details={aboutBannerDetails} />
      </section>

      <section className="common-section ">
        <div className="max-w-[1600px] mx-auto flex flex-col gap-32">
          {gallery.map((Details, index) => {
            return (
              <div>
                <h2 className="text-center text-[3.2rem] text-[#363636] font-playfair font-extrabold">
                  {Details.Title}
                </h2>
                <div className="mt-10">
                  <Swiper
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    navigation={{
                      nextEl: `.next-${index}`,
                      prevEl: `.prev-${index}`,
                    }}
                    modules={[Autoplay, Navigation, Pagination]}
                    slidesPerView={1}
                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                    }}
                    className="relative !z-0"
                  >
                    {Details.Images.map((img) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="md:h-[400px] h-[260px]">
                            <img
                              src={img}
                              alt=""
                              className="w-full h-full object-center object-cover"
                            />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>

                <div className="mt-20 flex gap-10 justify-center items-center">
                  <img
                    src={leftArrow}
                    alt="left-arrow"
                    className={`w-16 cursor-pointer btn-active prev-${index}`}
                  />
                  <img
                    src={rightArrow}
                    alt="right-arrow"
                    className={`w-16 cursor-pointer btn-active next-${index}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Gallery;
