import { BrowserRouter, Route, Routes } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";

import Services from "./pages/OurServices/Services";
import Footer from "./components/Footer/Footer";
import Gallery from "./pages/Gallery/Gallery";

function App() {
  // const router =
  return (
    // <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/service" element={<Services />} /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
